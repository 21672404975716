import * as React from "react"
import { Link } from "gatsby"

import Header from "../components/header"
import Seo from "../components/seo"

import * as Styles from "./styles/plan.module.scss"

const pageTitle = "お得なプランのご案内"

const PlanPage = () => (
    <div className="w-screen">
        <Header siteTitle={pageTitle} />
        <Seo title={pageTitle} />
        <main>
            <h1>{pageTitle}</h1>
            <div className={Styles.box}>
                <div className={Styles.plan} id="bookkeeping">
                    <h2>記帳プラン</h2>
                    <table>
                        <tr className={Styles.gyosei}>
                            <th></th>
                            <td className={Styles.lite}>ライト</td>
                            <td className={Styles.basic}>ベーシック</td>
                            <td className={Styles.premium}>プレミアム</td>
                        </tr>
                        <tr className={Styles.price}>
                            <th className={Styles.border_top_none}></th>
                            <td>
                                <s>45,000円/月</s><br />
                                <span className={Styles.price}>44,000</span>円/月<br />
                                <span className={Styles.discount}>1,000円お得!</span>
                            </td>
                            <td>
                                <s>55,000円/月</s><br />
                                <span className={Styles.price}>53,000</span>円/月<br />
                                <span className={Styles.discount}>2,000円お得!</span>
                            </td>
                            <td>
                                <s>85,000円/月</s><br />
                                <span className={Styles.price}>80,000</span>円/月<br />
                                <span className={Styles.discount}>5,000円お得!</span>
                            </td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>記帳<br />(~150仕訳)</th>
                            <td>〇</td>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>領収書等整理<br />(~50枚)</th>
                            <td>〇</td>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>領収書等回収</th>
                            <td>〇</td>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>税理士事務所<br />連絡代行</th>
                            <td className={Styles.gray_cell}>×</td>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>行政書士顧問</th>
                            <td className={Styles.gray_cell}>×</td>
                            <td className={Styles.gray_cell}>×</td>
                            <td>〇</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className={Styles.contact_button}>
                <a href="/gyosei/contact">
                    <button className={Styles.gyosei}>お問い合わせ</button>
                </a>
            </div>
            <div className={Styles.top_buttons}>
                <a href="/gyosei">
                    <button className={Styles.gyosei}>行政書士事務所白玉</button>
                </a>
                <a href="/support">
                    <button className={Styles.support}>オフィス白玉</button>
                </a>
            </div>
            <div className={Styles.box}>
                <div className={Styles.plan} id="general_affairs">
                    <h2>記帳・事務プラン</h2>
                    <table>
                        <tr className={Styles.mixplan}>
                            <th></th>
                            <td className={Styles.lite}>ライト</td>
                            <td className={Styles.basic}>ベーシック</td>
                            <td className={Styles.premium}>プレミアム</td>
                        </tr>
                        <tr className={Styles.price}>
                            <th className={Styles.border_top_none}></th>
                            <td>
                                <s>50,000円/月</s><br />
                                <span className={Styles.price}>49,000</span>円/月<br />
                                <span className={Styles.discount}>1,000円お得!</span>
                            </td>
                            <td>
                                <s>60,000円/月</s><br />
                                <span className={Styles.price}>58,000</span>円/月<br />
                                <span className={Styles.discount}>2,000円お得!</span>
                            </td>
                            <td>
                                <s>90,000円/月</s><br />
                                <span className={Styles.price}>85,000</span>円/月<br />
                                <span className={Styles.discount}>5,000円お得!</span>
                            </td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>記帳<br />(~150仕訳)</th>
                            <td>〇</td>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>領収書等整理<br />(~50枚)</th>
                            <td>〇</td>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>領収書等回収</th>
                            <td>〇</td>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>税理士事務所<br />連絡代行</th>
                            <td className={Styles.gray_cell}>×</td>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>行政書士顧問</th>
                            <td className={Styles.gray_cell}>×</td>
                            <td className={Styles.gray_cell}>×</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>おつかい<br />(1回/月)</th>
                            <td>〇</td>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className={Styles.contact_button}>
                <a href="/gyosei/contact">
                    <button className={Styles.gyosei}>お問い合わせ</button>
                </a>
            </div>
            <div className={Styles.top_buttons}>
                <a href="/gyosei">
                    <button className={Styles.gyosei}>行政書士事務所白玉</button>
                </a>
                <a href="/support">
                    <button className={Styles.support}>オフィス白玉</button>
                </a>
            </div>
            <div className={Styles.box}>
                <div className={Styles.plan} id="itweb">
                    <h2>IT・WEBプラン</h2>
                    <table>
                        <tr className={Styles.support}>
                            <th></th>
                            <td className={Styles.basic}>ベーシック</td>
                            <td className={Styles.premium}>プレミアム</td>
                        </tr>
                        <tr className={Styles.price}>
                            <th className={Styles.border_top_none}></th>
                            <td>
                                <s>40,000円/月</s><br />
                                <span className={Styles.price}>39,000</span>円/月<br />
                                <span className={Styles.discount}>1,000円お得!</span>
                            </td>
                            <td>
                                <s>70,000円/月</s><br />
                                <span className={Styles.price}>65,000</span>円/月<br />
                                <span className={Styles.discount}>5,000円お得!</span>
                            </td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>IT導入継続支援</th>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>WEBサイト管理</th>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>IT・WEB提携</th>
                            <td className={Styles.gray_cell}>×</td>
                            <td>〇</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className={Styles.contact_button}>
                <a href="/support/contact">
                    <button className={Styles.support}>お問い合わせ</button>
                </a>
            </div>
            <div className={Styles.top_buttons}>
                <a href="/gyosei">
                    <button className={Styles.gyosei}>行政書士事務所白玉</button>
                </a>
                <a href="/support">
                    <button className={Styles.support}>オフィス白玉</button>
                </a>
            </div>
            <div className={Styles.box}>
                <div className={Styles.plan} id="shiratama">
                    <h2>白玉プラン</h2>
                    <table className={Styles.big_table}>
                        <tr className={Styles.mixplan}>
                            <th></th>
                            <td className={Styles.basic}>ベーシック</td>
                            <td className={Styles.premium}>プレミアム</td>
                        </tr>
                        <tr className={Styles.price}>
                            <th className={Styles.border_top_none}></th>
                            <td>
                                <s>60,000円/月</s><br />
                                <span className={Styles.price}>58,000</span>円/月<br />
                                <span className={Styles.discount}>2,000円お得!</span>
                            </td>
                            <td>
                                <s>90,000円/月</s><br />
                                <span className={Styles.price}>85,000</span>円/月<br />
                                <span className={Styles.discount}>5,000円お得!</span>
                            </td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>記帳(~150仕訳)</th>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>領収書等整理<br />(~50枚)</th>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>領収書等回収</th>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>税理士事務所連絡代行</th>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>行政書士顧問</th>
                            <td className={Styles.gray_cell}>×</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>IT導入継続支援</th>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>WEBサイト管理</th>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>おつかい(1回/月)</th>
                            <td>〇</td>
                            <td>〇</td>
                        </tr>
                        <tr className={Styles.detail}>
                            <th>IT・WEB提携</th>
                            <td className={Styles.gray_cell}>×</td>
                            <td>〇</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className={Styles.contact_button}>
                <a href="/gyosei/contact">
                    <button className={Styles.gyosei}>お問い合わせ</button>
                </a>
            </div>
            <div className={Styles.top_buttons}>
                <a href="/gyosei">
                    <button className={Styles.gyosei}>行政書士事務所白玉</button>
                </a>
                <a href="/support">
                    <button className={Styles.support}>オフィス白玉</button>
                </a>
            </div>
        </main>
    </div>
)
export default PlanPage
// extracted by mini-css-extract-plugin
export var box = "plan-module--box--3yp7D";
export var plan = "plan-module--plan--nopiw";
export var gyosei = "plan-module--gyosei--354Go";
export var support = "plan-module--support--1MtI8";
export var mixplan = "plan-module--mixplan--6DDQd";
export var lite = "plan-module--lite--32arK";
export var basic = "plan-module--basic--EArT-";
export var premium = "plan-module--premium--2zFoO";
export var price = "plan-module--price--1xNkJ";
export var discount = "plan-module--discount--zouS9";
export var detail = "plan-module--detail--JNnLb";
export var gray_cell = "plan-module--gray_cell--3Y80C";
export var big_table = "plan-module--big_table--2ynnK";
export var contact_button = "plan-module--contact_button--X_DGt";
export var top_buttons = "plan-module--top_buttons--1QLpz";